<!--
  * @desc:
  * @author: 肖
  * @create_date: 2021/6/5
  * @BSD
-->
<template>
  <div class="empty-wrap">
    <img src="../assets/wuliu_img_default.png" mode="" />
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name:"my-empty",
    data() {
      return {

      };
    }
  }
</script>

<style lang="scss" scoped>
  .empty-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & img {
      width: 344px;
      height: 278px;
      margin: 0 auto 12px;
    }
  }
</style>
